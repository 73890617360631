<template>
  <div id="app">
    <img class="deconnexion" src="../assets/se-deconnecter.png" v-on:click="disconnect()" alt="Italian Trulli">
    
    <button v-on:click="goToUtilisateur()">Edit files</button>
    <button v-on:click="goToFichiers()">Files</button>
    
    <form @submit.prevent="" v-on:submit="upload()">
      <h1>Upload files</h1>
      
      <p>Choose your file type and its category</p>
      <select @change="accepts" id="type">
        <option value="PDF">PDF</option>
        <option value="PPT">PowerPoint</option>
        <option value="Video">Video</option>
      </select>

      <select name="categorie" id="categorie">
        <option value="vigilant_software_suite">Vigilant Software Suite</option>
        <option value="vigilant_master_med">Vigilant Master Med</option>
        <option value="vigilant_sentinel">Vigilant Sentinel</option>
        <option value="vigilant_insight">Vigilant Insight</option>
        <option value="vigilant_bridge">Vigilant Bridge</option>
        <option value="centerium">Vigilant Centerium</option>
        <option value="partner">Partner</option>
      </select>
      <div class="lineShadow">
        <label for="input">Select your file</label>
          <input type="file"
          enctype="multipart/form-data"
          id="input"
          class="input"
          @change="handleFiles"
          accept="application/pdf"
          required
          />
      </div>

      <div class="lineShadow">
          <label for="imageInput">Select a thumbnail for your file (.png, .jpg)</label>
          <input type="file"
          enctype="multipart/form-data"
          id="imageInput"
          class="imageInput"
          @change="onImageChange"
          accept=".png, .jpeg, .jpg"
          required
          />
      </div>


      <div class="lineShadow">
          <label>File title :</label>
          <input
          type="text"
          id="titre"
          placeholder="Title"
          required
          />
      </div>

      <button type="submit">Upload file</button>
    </form>
    <div v-if="url" id="preview">
      Thumbnail :
      <img :src="url" />
    </div>

    <iframe v-if="!bool" id="output" width="100%" height="500" frameborder="0">This is an embedded</iframe>
    <video v-if="bool" width="400" controls>
      <source src="" id="video_here" class="video_here">
        Your browser does not support HTML5 video.
    </video>
  </div>
</template>

<script>
const axios = require('axios');
import router from '../router'
var $ = require('jquery')
export default {
    components: {},
    data() {
        return {
        selectedFile: {
          type: "null"
        },
        bool: false,
        url: null
        };
    },
    methods: {
      accepts() {
        if (document.getElementById("type").value == 'PPT') {
          this.bool = false
          document.getElementById("input").setAttribute("accept",".ppt, .pptx")
        }
        else if (document.getElementById("type").value == 'Video') {
          this.bool = true
          document.getElementById("input").setAttribute("accept","video/mp4")
        }
        else {
          this.bool = false
          document.getElementById("input").setAttribute("accept","application/pdf")
        }
      },
      async upload() {
        if (document.getElementById('input').files[0].type == "video/mp4" && document.getElementById("type").value != 'Video' 
          || (document.getElementById('input').files[0].type == "video/mp4" || document.getElementById('input').files[0].type == "application/pdf") && document.getElementById("type").value == 'PPT'
            || document.getElementById('input').files[0].type == "application/pdf" && document.getElementById("type").value != 'PDF') {
              alert("Selected type doesn't match with selected file type !")
              return
            }
        if (document.getElementById('input').files.length == 0 || document.getElementById('imageInput').files.length == 0) {
          alert("Please choose a file")
          return
        }
        const input = document.getElementById('input')
        const file = input.files[0]
        const imageInput = document.getElementById('imageInput')
        const image = imageInput.files[0]
        const formData = new FormData()
        formData.append('file', file)
        formData.append('image', image)
        formData.append("username",this.$session.get('username'))
        formData.append("categorie", document.getElementById('categorie').value)
        formData.append("type", document.getElementById('type').value)
        formData.append("titre", document.getElementById('titre').value)

        var self = this;
        
        const res = await axios.post("https://api.fkv-vss.com/api/Upload", formData
        ).then(function (response) {
          if (response.data.message === 'The file name or file original name already exists in the same category') {
            alert(response.data.message)
          }
          else {
            self.goToUtilisateur()
            return res;
          }
        })
      },
      onImageChange(e) {
        const file = e.target.files[0];
        if (file != undefined) {
          this.url = URL.createObjectURL(file);
        }
        else {
          this.url = undefined
        }
      },
      handleFiles() {
          if (document.getElementById("input").files.length == 0) {return}
          const inputElement = document.getElementById("input").files[0];
          const output = document.getElementById("output");
          const selected_file = inputElement;
          this.selectedFile = selected_file;
          if (this.selectedFile.type == "application/pdf") {
            const reader = new FileReader();
            reader.addEventListener("load", (event) => {
            output.src = event.target.result;
            });
            reader.readAsDataURL(selected_file);
          }
          else if (this.selectedFile.type == "video/mp4") {
            var $source = $('source#video_here.video_here')
            $source[0].src = URL.createObjectURL(document.getElementById("input").files[0]);
            $source.parent()[0].load();
          }
          
          else if (this.selectedFile.type == ".ppt, .pptx") {return}
      },
      disconnect() {
            this.$session.destroy()
            router.push('/').catch(() => {})
        },
      goToUtilisateur: function () {
          router.push('/Utilisateur').catch(() => {})
      },
      goToFichiers: function () {
          router.push('/Fichiers').catch(() => {})
      },
    },
};
</script>

<style>
input[type=file] {
  width: 100%;
}
input[type=text] {
  width: 100%;
}
.lineShadow {
  text-align: left;
}
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 25%;
  height:auto;
}
</style>